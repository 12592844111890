import './TopHeader.less';
import React from 'react';
import { Dropdown, Menu } from 'antd';

const { api } = window.common;

export default class RoleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.roleWithFarmList = window.user && window.user.roleWithFarmList;
    this.currentRoleKey = (window.user && window.user.roleKey) || this.roleWithFarmList[0].roleKey;
    this.currentRole = this.roleWithFarmList.find(a => a.roleKey === this.currentRoleKey);
  }

  onClickRole = (roleKey) => {
    api({
      method: 'post',
      url: '/cust/user/updateRole',
      data: { roleKey }
    }).then(() => {
      window.location.reload();
    });
  }

  getRoleItem = (role) => {
    if (!role) { return false; }
    return (
      <div className="role-menu-item">
        <img src={role.avatar} alt="" />
        {role.roleName}
      </div>
    );
  }

  render() {
    const menu = (
      <Menu onClick={(item) => { this.onClickRole(item.key); }}>
        {this.roleWithFarmList.map(role => (
          <Menu.Item key={role.roleKey}>
            {this.getRoleItem(role)}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Dropdown className="top-btn role-dropdown" overlayClassName="role-dropdown" overlay={menu} trigger={['click']}>
        <div>{this.getRoleItem(this.currentRole)}</div>
      </Dropdown>
    );
  }
}
