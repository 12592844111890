import util from './util';

const docLinks = {
  help: {
    en: 'https://bdminerplus.zendesk.com/hc/en-us',
    zhtw: 'https://bdminerplus.zendesk.com/hc/zh-sg'
  },
  ding: {
    en: 'https://bdminerplus.zendesk.com/hc/en-us/articles/12104080429337-How-to-create-a-webhook-for-a-DingTalk-alarm',
    zhtw: 'https://bdminerplus.zendesk.com/hc/zh-sg/articles/12104080429337-%E9%92%89%E9%92%89%E5%91%8A%E8%AD%A6%E7%9A%84webhook%E5%A6%82%E4%BD%95%E8%AE%BE%E7%BD%AE-',
  },
  overlock: {
    en: 'https://bdminerplus.zendesk.com/hc/en-us/articles/9876983152921-MiningOS-Installation-Tutorial',
    zhtw: 'https://bdminerplus.zendesk.com/hc/zh-sg/articles/9876983152921-MiningOS-%E5%AE%89%E8%A3%85%E6%95%99%E7%A8%8B',
  },
  pool: {
    en: 'https://bdminerplus.zendesk.com/hc/en-us/articles/12104742890521-How-to-get-a-Pool-Observer-Link',
    zhtw: 'https://bdminerplus.zendesk.com/hc/zh-sg/articles/12104742890521-%E5%A6%82%E4%BD%95%E8%8E%B7%E5%8F%96%E7%9F%BF%E6%B1%A0%E8%A7%82%E5%AF%9F%E8%80%85%E9%93%BE%E6%8E%A5'
  },
  modelType: {
    en: 'https://bdminerplus.zendesk.com/hc/en-us/articles/12557268150553-Miner-that-support-sleep-mode',
    zhtw: 'https://bdminerplus.zendesk.com/hc/zh-sg/articles/12557268150553-支持sleep模式的矿机型号'
  }
};

const isTest = document.domain.includes('deeppos');
const ticket = util.getCookie('service_ticket') || '';
const websiteLinks = {
  account: `https://${isTest ? 'account-test.bitdeer.vip' : 'account.bitdeer.com'}/${window.Bitmain.lng}?minerplus=1`, //用户中心
  minerplus: `https://${isTest ? 'www-test.bitdeer.vip' : 'www.bitdeer.com'}/${window.Bitmain.lng}/minerplus?minerplus=1&service_ticket=${ticket}`, //官网
  stockUrl: `https://${isTest ? 'inv-pre.bitdeer.vip' : 'inv.bitdeer.vip'}`, //库存系统
};


export default { docLinks, websiteLinks };
