const menu = (state = { menus: [] }, action) => {
  switch (action.type) {
    case 'MENUS':
      return {
        ...state,
        menus: action.menus,
      };
    default:
      return state;
  }
};

export default menu;
