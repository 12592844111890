import React, { Component } from 'react';
import { Icon, Carousel } from 'antd';
import MessageView from 'components/MessageView';
import ShowImportant from './ShowImportant';

const { api, util } = window.common;
export default class SystemAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alarmList: [], importantList: [], visibleView: false, visibleImportant: false
    };
    this.getSystemAlarm();
  }

  componentWillUnmount() {
    if (this.alarmTimer) {
      clearTimeout(this.alarmTimer);
    }
    if (this.sliderTimer) {
      clearInterval(this.sliderTimer);
    }
  }

  getSystemAlarm = () => {
    let params;
    if (window.user && window.user.obsFlag) {
      params = { obsFlag: 1, minerFarmId: window.Bitmain.minerFarmId };
    }
    api({
      params: { ...params, zone: util.timezone.tz.guess() },
      url: '/al/information/valid',
    }).then((res) => {
      this.setState({
        alarmList: res.data || [],
        visibleImportant: res.data.filter(item => item.show).length > 0,
        importantList: res.data.filter(item => item.show)
      }, this.setAlarmTimer);
    }, this.setAlarmTimer);
  };

  setAlarmTimer = () => {
    if (this.alarmTimer) {
      clearTimeout(this.alarmTimer);
    }
    this.alarmTimer = setTimeout(this.getSystemAlarm, 30000);
    if (!this.sliderTimer) {
      this.sliderTimer = setInterval(() => this.slider && this.slider.next(), 8000);
    }
  };

  renderCarousel = data => (
    <Carousel ref={slider => (this.slider = slider)} dots={false} dotPosition="bottom" speed={3000}>
      {data.map(item => (
        <div className="alarm-desc" onClick={() => { this.viewItem(item); }}>{item.title}</div>
      ))}
    </Carousel>
  );

  renderAlarm = data => (
    <div className="alarm-desc" onClick={() => { this.viewItem(data); }}>
      {data.title}
    </div>
  );

  viewItem = (item) => {
    this.setState({
      visibleView: true,
      detail: item,
    });
  };

  onClose = () => {
    this.setState({ visibleView: false, visibleImportant: false });
  };

  render() {
    const alarmList = this.state.alarmList;
    if (!alarmList || alarmList.length === 0) {
      return null;
    }
    return (
      <div className="bm-alarm-carousel" >
        <Icon type="sound" className="alarm-icon" />
        {alarmList.length > 1 ? this.renderCarousel(alarmList) : this.renderAlarm(alarmList[0])}
        {this.state.visibleView && (
          <MessageView detail={this.state.detail} onClose={this.onClose} />
        )}
        {this.state.visibleImportant && (
          <ShowImportant importantList={this.state.importantList} onClose={this.onClose} />
        )}
      </div>
    );
  }
}
