import './Home.less';
import React from 'react';
import { Button } from 'antd';
import IconFont from 'components/IconFont';
import FarmAdd from '../../Farm/FarmAdd';

const { locale } = window.common;

export default class PoolGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visibleAdd: false };
  }

  addFarm = () => {
    this.setState({ visibleAdd: true });
  };

  onClose = (isRefresh) => {
    this.setState({ visibleAdd: false });
    if (isRefresh) {
      location.href = '#/';
    }
  };

  render() {
    return (
      <div className="bm-home-container">
        <div className="home-bg">
          <IconFont type="iconxingzhuangjiehe" style={{ fontSize: '92px', marginTop: '100px' }} />
          <h3>{locale('您还没有矿场')}</h3>
          <p>{locale('点击“新增”，输入矿场基本信息')}，</p>
          <p>{locale('将“矿场ID”提供给工作人员')}。</p>
          <Button onClick={this.addFarm} type="primary">{locale('新增')}</Button>
        </div>
        {this.state.visibleAdd && (
          <FarmAdd onClose={this.onClose} firstFarm />
        )}
      </div>
    );
  }
}
