import React from 'react';
import Routes from './Routes';

const { api } = window.common;
export default class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.getUserInfo();
  }

  /*** 异常捕获 ***/
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error(errorInfo);
  }

  getUserInfo() {
    api({
      url: '/cust/user/getLoginInfo',
    }).then(({ data }) => {
      window.user = data || {};
      // 埋点统计需要用到当前矿场信息
      const currentRoleWithFarmList = data?.roleWithFarmList.find(role => role.roleKey === data.roleKey);
      window.farmList = currentRoleWithFarmList?.farmList;
      this.setState({ visible: true });
    });
  }

  render() {
    return this.state.visible && <Routes hasAuth />;
  }
}
