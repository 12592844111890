import './NoNuc.less';
import React from 'react';
import {
  Row, Col, message, Popconfirm
} from 'antd';
import IconFont from 'components/IconFont';
import RoleSelect from 'components/TopHeader/RoleSelect';
import FarmAdd from '../../Farm/FarmAdd';
import img from '../../../assets/images/no-nuc.png';

const { locale, api, hasAuth } = window.common;

export default class NoNuc extends React.Component {
  constructor(props) {
    super(props);
    this.farmList = (window.user && window.user.roleKey)
      ? window.user.roleWithFarmList.find(a => a.roleKey === window.user.roleKey).farmList
      : window.user.roleWithFarmList[0].farmList;
    this.state = { farmCode: '' };
  }

  copyFarmCode = (a) => {
    this.setState({ farmCode: a.farmCode }, () => {
      document.getElementById('farmCode').select();
      document.execCommand('Copy');
      message.success(locale('复制成功'));
    });
  }

  del = (item) => {
    api({
      loading: true,
      method: 'post',
      url: '/cust/farm/delete',
      data: { minerFarmId: item.id },
    }).then(() => {
      window.notification.success({ message: locale('操作成功') });
      window.location.reload();
    });
  };

  showFarmAdd = () => {
    this.setState({ visible: true });
  };

  onClose = () => { this.setState({ visible: false }); };

  render() {
    const { farmCode, visible } = this.state;
    return (
      <div className="no-nuc-page">
        <input id="farmCode" value={farmCode} style={{ position: 'fixed', top: '-100px', opacity: 0 }} />
        <div className="no-nuc-header">
          <RoleSelect/>
          <IconFont className="add-farm" type="iconadd" onClick={this.showFarmAdd} />
        </div>
        <div className="no-nuc-inner">
          <img src={img} alt="" />
          <h3>{locale('您还没有授权码')}</h3>
          <p>{locale('请联系工作人员获取授权码')}</p>
          <div className="farm-list">
            <Row gutter={16}>
              <Col span={9} className="list-title">{locale('矿场名称')}</Col>
              <Col span={12} className="list-title">{locale('矿场ID')}</Col>
            </Row>
            {
              this.farmList.map(a => (
                <Row gutter={16}>
                  <Col span={9} title={a.farmName}>{a.farmName}</Col>
                  <Col span={12}>{a.farmCode}</Col>
                  <Col span={3}>
                    <IconFont className="icon" type="iconfuzhi" onClick={() => { this.copyFarmCode(a); }} />
                    {
                      hasAuth('priv-farm-management-delete', a.permissionCodes) && (
                      <Popconfirm
                        title={a.confirmMessage || locale('确定要删除吗?')}
                        onConfirm={() => { this.del(a); }}
                        okText={locale('确定')}
                        cancelText={locale('取消')}>
                        <IconFont className="icon" type="iconshanchu" onC />
                      </Popconfirm>
                      )
                    }

                  </Col>
                </Row>
              ))
            }
          </div>
        </div>
        {visible && <FarmAdd onClose={this.onClose} />}
      </div>
    );
  }
}
