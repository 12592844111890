import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import setCurrentFarm from './setCurrentFarm';
import setMenus from './setMenus';

export default combineReducers({
  menu: setMenus,
  farm: setCurrentFarm,
  routing: routerReducer,
});
