// 枚举
import locale from '../i18n';

const enums = {
  networkStatus: [
    { value: 'Y', name: locale('在线') },
    { value: 'N', name: locale('离线') },
  ],
  alarmStatus: [
    { value: 'Y', name: locale('已处理') },
    { value: 'N', name: locale('未处理') },
  ],
  taskStatus: [ //任务状态
    { value: 1, name: locale('待接收任务') },
    { value: 2, name: locale('待执行') },
    { value: 3, name: locale('正在执行') },
    { value: 4, name: locale('已完成') },
    { value: 5, name: locale('取消中') },
    { value: 6, name: locale('已取消') },
    { value: 7, name: locale('预创建') },
    { value: 8, name: locale('保存中') },
    { value: 9, name: locale('已保存') },
    { value: 10, name: locale('执行成功') },
    { value: 11, name: locale('执行失败') },
    { value: 12, name: locale('取消中') },
  ],
  nucTypeList: [//服务器管理-矿机类别
    { value: 1, name: 'YardOS' },
    { value: 2, name: 'MiningGuard' },
    // { value: 3, name: 'MiningBee' },
  ],
  interceptionRule: [//日志中心-业务类型
    { value: 1, name: locale('矿机操作') },
    { value: 2, name: locale('NUC操作') },
    { value: 3, name: locale('矿场管理') },
    { value: 4, name: locale('其他设置') },
  ],
  positionList: [//ip网段起始物理位置
    { value: 'LU', name: locale('左上') },
    { value: 'RU', name: locale('右上') },
    { value: 'LD', name: locale('左下') },
    { value: 'RD', name: locale('右下') },
  ],
  passwordTypeList: [//密码管理方式
    { value: 1, name: locale('全场统一密码'), },
    { value: 2, name: locale('指定矿机品牌'), },
    { value: 3, name: locale('指定IP段'), }
  ],
  assetStatus: [//资产矿机状态
    { value: 1, name: locale('架上运行'), },
    { value: 9, name: locale('架上停机'), },
    { value: 3, name: locale('维修中'), },
    { value: 14, name: locale('转场'), },
    { value: 13, name: locale('待销售'), },
    { value: 15, name: locale('停机'), },
    { value: 7, name: locale('已报废'), },
    { value: 4, name: locale('已出库'), },
    { value: 16, name: locale('其他'), },
  ],
  assetStatusOld: [//老资产矿机状态
    { value: 1, name: locale('架上运行'), },
    { value: 2, name: locale('新到场'), },
    { value: 3, name: locale('维修中'), },
    { value: 4, name: locale('已出库'), },
    { value: 5, name: locale('转场'), },
    { value: 6, name: locale('待销售'), },
    { value: 7, name: locale('已报废'), },
    { value: 8, name: locale('已下架'), }
  ],
  forecastLevels: [//收益风险日报等级
    { value: 1, name: locale('紧急'), },
    { value: 2, name: locale('严重'), },
    { value: 3, name: locale('戒备'), },
    { value: 4, name: locale('正常'), }
  ],
  coinTypeListForPool: [
    { value: 'default', name: locale('默认币种') },
    { value: 'ETH', name: 'ETH' },
    { value: 'ETC', name: 'ETC' },
  ],
  mererLevels: [
    // { value: 0, name: locale('根') },
    { value: 1, name: locale('一级') },
    { value: 2, name: locale('二级') },
    { value: 3, name: locale('三级') },
    { value: 4, name: locale('四级') },
  ],
  HashSnPositionEnum: [
    { value: 'POSITION_ONE', name: 'Chain 0 HB SN#' },
    { value: 'POSITION_TWO', name: 'Chain 1 HB SN#' },
    { value: 'POSITION_THREE', name: 'Chain 2 HB SN#' },
    { value: 'POSITION_FOUR', name: 'Chain 3 HB SN#' },
  ],
  deliveryTypeList: [
    { value: 'delivery', name: locale('发货', 'Handling') },
    { value: 'repair', name: locale('继续维修') }
  ],
  testingResultList: [
    { value: 'SUCCESS', name: locale('通过') },
    { value: 'FAILED', name: locale('不通过') }
  ],
  // 传感器类型
  sensorType: [
    { value: 0, name: locale('环境传感器') },
    { value: 1, name: locale('电缆传感器') }
  ],
  // 传感器属性
  locationAttribute: [
    { value: 0, name: locale('室内') },
    { value: 1, name: locale('室外') }
  ],
  // 物理位置级别
  locationLevel: [
    { value: 1, name: locale('一级') },
    { value: 2, name: locale('二级') }
  ],
};

Object.keys(enums).forEach((key) => {
  const arr = enums[key];
  if (Array.isArray(arr)) {
    const obj = {};
    arr.map(item => obj[item.value] = item.name);
    enums[`${key}Text`] = obj;
  }
});

export default enums;
