import React from 'react';
import {
  Modal, Row, Col
} from 'antd';

const { locale, util } = window.common;
export default class MessageView extends React.Component {
  constructor(props) {
    super(props);
    this.detail = this.props.detail;
  }

  handleCancel = () => {
    this.props.onClose();
  };

  export = () => {
    const params = {};
    params.antToken = window.user && window.user.token;
    window.location.href = `${this.detail.annexPath}&${util.queryParams(params)}`;
  };


  render() {
    const { detail } = this;
    return (
      <Modal
        maskClosable={false}
        visible
        destroyOnClose
        className="bm-view"
        title={locale('详情')}
        width="720px"
        onCancel={this.handleCancel}
        footer={null}
      >
        <div className="area-body">
          <Row gutter={32}>
            <Col span={24} className="bm-label">{locale('消息标题')}：</Col>
            <Col span={24} className="bm-desc">{detail.title}</Col>
          </Row>
          <Row gutter={32}>
            <Col span={24} className="bm-label">{locale('消息内容')}：</Col>
            <Col
              span={24}
              className="bm-desc"
              dangerouslySetInnerHTML={{ __html: detail.description && detail.description.replace(/(\r\n)|(\n)/g, '<br/>') }} />
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className="bm-label"> {locale('消息级别')}：</div>
              <div className="bm-desc">{detail.infoLevelTitle}</div>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24} className="bm-label">{locale('附件')}：</Col>
            <Col span={24}>
              {
               detail.annexName ? (
                 <a onClick={this.export}>
                   {detail.annexName}
                 </a>
               ) : locale('无')
             }
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
