import React from 'react';
import { Layout, Spin } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageHeader from './components/PageHeader';
import NoNuc from '../pages/Home/NoNuc';
import NoFarm from '../pages/Home/NoFarm';
import RoleSelect from '../pages/Home/RoleSelect';

const { api } = window.common;

export default class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: !!window.user,
    };
    if (!window.user) {
      this.getUserInfo();
    }
  }

  /*** 异常捕获 ***/
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error(errorInfo);
  }

  getUserInfo() {
    api({
      url: '/cust/user/getLoginInfo',
    }).then((res) => {
      const data = res.data;
      const enabledRoles = data.roleWithFarmList.filter(a => a.enabled).map(a => a.roleKey);

      if (data.roleWithFarmList && data.roleWithFarmList.length === 1 && !data.roleKey) {
        data.roleKey = data.roleWithFarmList[0].roleKey;
      }
      window.user = res.data || {};
      if (data.roleWithFarmList && data.roleWithFarmList.length > 0) {
        if (!enabledRoles.includes(data.roleKey)) {
          window.location.href = '#/home/no-nuc';
        }
        if (!data.roleKey) {
          window.location.href = '#/home/role-select';
        }
        if (data.roleKey && enabledRoles.includes(data.roleKey)) {
          window.location.href = '/#/';
          return false;
        }
      } else {
        window.location.href = '#/home/no-farm';
      }
      this.setState({ visible: true });
    });
  }

  render() {
    const { visible } = this.state;
    return (
      <Layout>
        {
          visible && (<>
            <PageHeader hideSystemAlert />
            <Switch>
              <Route path="/home/no-farm"><NoFarm /></Route>
              <Route path="/home/no-nuc"><NoNuc /></Route>
              <Route path="/home/role-select"><RoleSelect /></Route>
              <Route path="/home/*"><Redirect to="/" /></Route>
            </Switch></>
          )
        }
        <div className="bm-loading"><Spin className="loading-icon" size="large" /></div>
      </Layout>
    );
  }
}
