const farm = (state = { isVirtual: false }, action) => {
  switch (action.type) {
    case 'CURRENTFARM':
      return {
        ...state,
        name: action.name,
        roleKey: action.roleKey,
        permissionCodes: action.permissionCodes,
        minerFarmId: action.minerFarmId,
        isVirtual: action.farmType === 'virtual',
        isInternal: action.ownership === 'internal' || action.ownership === 'test',
        isExternal: !action.assetFlag,
        ammeterFlag: action.ammeterFlag,
        repairFlag: action.repairFlag,
        timezone: action.timezone,
        collectGroupId: action.collectGroupId,
      };
    default:
      return state;
  }
};

export default farm;
