import axios from './axios';
import util from './util';
/**
 * 封装请求接口
 * @param {object} options axios的配置
 * @param {object} cusOptions 请求自定义配置
 * @param {boolean} cusOptions.showError 错误提示是否自动关闭，code大于2000不关闭
 * @returns {Promise<AxiosResponse<any>>}
 */
const api = (options, cusOptions) => {
  cusOptions = Object.assign({
    apiServer: window.apiServer,
    timeout: 30 * 1000,
  }, cusOptions);
  const url = (cusOptions && cusOptions.apiServer) ? cusOptions.apiServer + options.url : options.url;
  const method = options.method || 'get';
  const cusHeaders = options.headers || {};
  const headers = { ...cusHeaders, 'Ant-Token': (window.user && window.user.token) };
  //在header中设置API-VERSION的值  默认值为1  开启后设置为flink。
  if (window.location.hostname.includes('deeppos') || localStorage.getItem('API-VERSION')) {
    headers['API-VERSION'] = localStorage.getItem('API-VERSION');
  }
  util.setCookie('timezone', util.timezone.tz.guess());
  if (!window.loadingElement) {
    [window.loadingElement] = document.getElementsByClassName('bm-loading');
  }
  if (options.loading && window.loadingElement) {
    window.loadingElement.style.display = 'block';
  }
  const req = axios({
    ...options,
    url,
    method,
    headers,
  });

  req.catch((err) => {
    if (options.loading && window.loadingElement) {
      window.loadingElement.style.display = 'none';
    }
    console.error('Network Error::', err, options);
  });

  return req.then((response) => {
    if (options.loading && window.loadingElement) {
      window.loadingElement.style.display = 'none';
    }
    const res = response.data;
    console.log('response::', res, options);
    res.code = `${res.code}`;//转string，防止下面报错
    const cookieLng = util.getCookie(window.location.host.indexOf('obs') > -1 ? 'OBS_ANTSENTRY-LNG' : 'ANTSENTRY-LNG');
    if (res.code === '302' || (cookieLng && (cookieLng !== window.Bitmain.lng))) { //语言更新
      window.location.reload();
      return res;
    }
    if (res.code === '0' || res.code === '200' || (options.ignoreCodes && options.ignoreCodes.indexOf(res.code) > -1)) {
      return res;
    }
    if (res.code === '403' && res.data) { // 未登录
      location.href = res.data.url;
    } else if (res.message) {
      if (res.code && res.code > 2000) {
        options.showError = true;
      }
      window.notification.error(options.showError ? {
        message: res.message,
        duration: null,
      } : { message: res.message });
    }
    return Promise.reject(res);
  });
};

export default api;
