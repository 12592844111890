import './RoleSelect.less';
import React from 'react';
import IconFont from 'components/IconFont';

const { locale, api } = window.common;

export default class RoleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleWithFarmList: window.user.roleWithFarmList.map(a => ({
        ...a,
        open: false,
        height: (a.farmList.length >= 3 ? 3 : a.farmList.length) * 28
      }))
    };
  }

  onClickRole = (roleKey) => {
    api({
      method: 'post',
      url: '/cust/user/updateRole',
      data: { roleKey }
    }).then(() => {
      window.location.href = '/#/';
    });
  }

  render() {
    const { roleWithFarmList } = this.state;
    return (
      <div className="bm-role-select-container">
        <h3>{locale('您好，欢迎登录')}</h3>
        <span>{locale('您可以选择下列角色登录本系统')}</span>
        <div className="role-select">
          <div className="role-info">
            {
              roleWithFarmList.map(a => (
                <div className="role-item" onClick={() => { this.onClickRole(a.roleKey); }}>
                  <img src={a.avatar} alt="" />
                  <p>{a.roleName}</p>
                  <span >{a.desc}</span>
                </div>
              ))
            }
          </div>
          <div className="farm-info">
            {
            roleWithFarmList.map(a => (
              <div className="farm-item">
                <div className="farm-list" style={{ height: a.height }}>
                  {a.farmList && a.farmList.map(farm => (<p className="farm-name">{farm.farmName}</p>))}
                </div>
                {
                a.farmList && a.farmList.length > 3
                  && (
                  <IconFont
                    className={a.open ? 'more more-trans' : 'more'}
                    onClick={() => {
                      this.setState({
                        roleWithFarmList: roleWithFarmList.map((item) => {
                          if (item.roleKey === a.roleKey) {
                            const length = a.farmList.length;
                            item.height = (item.open ? 3 : length) * 28;
                            item.open = !a.open;
                          }
                          return item;
                        })
                      });
                    }}
                    type="iconmore" />
                  )
                }
              </div>
            ))
          }
          </div>
        </div>
      </div>
    );
  }
}
