import React from 'react';
import {
  Form, Modal, Input, InputNumber, Row, Col, Button, message, Select, Radio
} from 'antd';

const { locale, api, util } = window.common;
class FarmAdd extends React.Component {
  constructor(props) {
    super(props);
    this.roleKey = window.user.roleKey;
    this.detail = this.props.detail || {};
    this.firstFarm = this.props.firstFarm;
    this.isEdit = !!this.detail.minerFarmId;
    this.state = {
      title: this.isEdit ? locale('修改矿场信息') : locale('新增'),
      newFarmId: '',
      unitList: []
    };
  }

  componentDidMount() {
    document.oncopy = (e) => {
      if (this.state.newFarmId) {
        e.clipboardData.setData('text/plain', this.state.newFarmId);
        e.preventDefault();
      }
    };
    api({
      url: '/cust/electricity/unit/list',
    }).then((res) => {
      this.setState({ unitList: res.data.map(a => ({ value: a.electricityPriceUnitIds, name: a.electricityPriceUnit })) });
    });
  }

  componentWillUnmount() {
    document.oncopy = null;
  }

  handleOk = () => {
    if (this.state.newFarmId) {
      if (this.roleKey !== 'farm.adm') {
        api({
          method: 'post',
          url: '/cust/user/updateRole',
          data: { roleKey: 'farm.adm' }
        }).then(() => {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
      return false;
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const options = this.isEdit ? {
          url: '/cust/farm/mod',
          data: {
            ...values,
            name: util.trim(values.name),
            minerFarmId: this.detail.minerFarmId,
          },
        } : {
          url: '/cust/farm/add',
          data: {
            ...values,
            name: util.trim(values.name),
          },
        };
        api({
          ...options,
          loading: true,
          method: 'post',
        }).then((res) => {
          window.notification.success({ message: locale('操作成功') });
          if (this.isEdit) {
            this.props.onClose(true);
          } else {
            this.setState({ newFarmId: res.data.farmCode, title: locale('矿场ID') });
          }
        });
      }
    });
  };

  handleCancel = () => {
    if (this.state.newFarmId) {
      window.location.reload();
    } else {
      this.props.onClose();
    }
  };

  copyFarmId = () => {
    document.execCommand('Copy'); // 执行浏览器复制命令
    message.success(locale('复制成功'));
  };

  getValueFromEvent = (e) => {
    let transformIndex = 0;
    let value = util.removeEmoji(e.target.value);
    const pattern = new RegExp('[\u4E00-\u9FA5]+');
    for (let i = 0; i < value.length; i++) {
      transformIndex += pattern.test(value[i]) ? 2 : 1;
      if (transformIndex > 24) {
        value = value.slice(0, i);
        break;
      }
    }
    return value;
  }

  getUnitSelect = () => this.props.form.getFieldDecorator('electricityPriceUnitIds', {
    initialValue: this.detail.electricityPriceUnitIds || (this.state.unitList[0] && this.state.unitList[0].value),
  })(
    <Select disabled={this.isEdit && this.detail.electricityPriceUnitIds} style={{ width: '120px' }} dropdownMatchSelectWidth={false}>
      {this.state.unitList.map(a => <Select.Option value={a.value}>{a.name}</Select.Option>)}
    </Select>,
  );

  render() {
    return (
      <Modal
        maskClosable={false}
        visible
        destroyOnClose
        className="bm-modal"
        title={this.state.title}
        width="440px"
        onCancel={this.handleCancel}
        footer={this.roleKey !== 'farm.adm' || !this.state.newFarmId ? [
          <Button onClick={this.handleCancel}>{locale('取消')}</Button>,
          <Button type="primary" onClick={this.handleOk}>{locale('确定')}</Button>,
        ] : null}
      >
        {!this.state.newFarmId && (
        <Form>
          <Row style={{ padding: '0 20px' }}>
            <Col span={24}>
              <Form.Item label={locale('矿场名称')}>
                {this.props.form.getFieldDecorator('name', {
                  initialValue: this.detail.name,
                  rules: [
                    { required: true, whitespace: true, message: locale('内容不能为空'), },
                    {
                      min: 4,
                      max: 24,
                      transform: (a) => {
                        const pattern = new RegExp('[\u4E00-\u9FA5]+');
                        if (!a) { return ''; }
                        a = a.split('').map(b => (pattern.test(b) ? b + b : b));
                        return a.join('');
                      },
                      message: locale('请输入长度为{0}~{1}的字符').format(4, 24),
                    }
                  ],
                  getValueFromEvent: this.getValueFromEvent
                })(
                  <Input maxlength="24" placeholder={locale('请输入')} />,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={locale('监控模式')}>
                {this.props.form.getFieldDecorator('type', {
                  initialValue: this.detail.type,
                  rules: [{ required: true, message: locale('请选择'), }],
                })(
                  <Radio.Group disabled={this.isEdit} >
                    <Radio value="normal">{locale('自建服务器')} </Radio>
                    <Radio
                      value="virtual"
                      style={{
                        display: 'flex',
                        alignItems: 'self-start',
                        marginTop: '10px',
                        whiteSpace: 'break-spaces'
                      }}>
                      {locale('共享服务器（与其他矿场共用服务器。前提条件：矿场之间网络互通。）')}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={locale('计划矿机总数')}>
                {this.props.form.getFieldDecorator('capacity', {
                  initialValue: this.detail.capacity,
                  rules: [{ required: true, message: locale('内容不能为空'), },
                    {
                      type: 'number',
                      min: 0,
                      max: 100000,
                      transform: util.toNumber,
                      message: locale('请输入{0}~{1}之间的数字').format(0, 100000),
                    }],
                })(
                  <InputNumber
                    min={0}
                    max={100000}
                    maxlength="9"
                    placeholder={locale('请输入')} />,
                )}
              </Form.Item>
              {
                  this.props.form.getFieldValue('type') === 'normal'
                  && (
                  <Form.Item label={locale('电厂结算电价')}>
                    {this.props.form.getFieldDecorator('price', {
                      initialValue: this.detail.price,
                      rules: [
                        {
                          required: true,
                          validator: (rule, value, callback) => {
                            if (value <= 0 || value >= 50 || !util.isNumber(util.toNumber(value)) || !value) {
                              callback(locale('请输入{0}~{1}之间的数字').format(0, 50));
                            }
                            if (`${value}`.includes('.') && `${value}`.split('.')[1].length > 4) {
                              callback(locale('最多保留{0}位小数').format(4));
                            }
                            callback();
                          }
                        }],
                    })(
                      <Input
                        addonAfter={this.getUnitSelect()}
                        maxlength="10"
                        placeholder={locale('请输入')} />,
                    )}
                  </Form.Item>
                  )
                }
            </Col>
          </Row>
        </Form>
        )}
        {this.state.newFarmId
        && (
          <Row style={{ padding: '10px 0', lineHeight: '32px' }}>
            <Col span={20}>{this.state.newFarmId}</Col>
            <Col span={4}>
              <Button onClick={this.copyFarmId}>{locale('复制')}</Button>
            </Col>
            {this.roleKey !== 'farm.adm' && !this.firstFarm && <Col span={24} style={{ marginTop: '20px', textAlign: 'center' }}>{locale('您的账户在该矿场为管理员角色，是否切换角色？')}</Col>
}          </Row>
        )}
      </Modal>
    );
  }
}

export default Form.create()(FarmAdd);
