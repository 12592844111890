import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import IconFont from 'components/IconFont';
import { connect } from 'react-redux';

const { locale, getNoAuthPath } = window.common;
// 通过pathname获取openKeys
const findSelectedKeys = (menus, currentPath) => {
  const recursiveSearch = (menuList = [], crumb = []) => {
    for (let i = 0; i < menuList.length; i += 1) {
      const menu = menuList[i];
      if (menu.path && currentPath.startsWith(menu.path)) {
        crumb.push(menu);
        break;
      } else if (menu?.children?.length > 0) {
        recursiveSearch(menu.children, crumb);
      }
      if (crumb.length > 0) {
        crumb.push(menu);
        break;
      }
    }
    return crumb;
  };
  const paths = recursiveSearch(menus, []);
  const defaultOpenKeys = paths?.map(menu => menu.id);
  return defaultOpenKeys;
};

const LeftMenu = ({ farm, menus, location }) => {
  const { pathname } = location;
  const obsFlag = window.user.obsFlag;
  const defaultOpenKeys = findSelectedKeys(menus, pathname);
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const hidePath = (cell) => {
    const noAuthPath = getNoAuthPath(farm, obsFlag);
    return !noAuthPath.includes(cell.path);
  };

  // 只打开一个菜单
  const onOpenChange = (keys) => {
    const level1 = menus.map(a => a.id);
    const level1OpenKeys = keys.filter(a => level1.includes(a));
    let level2OpenKeys = keys.filter(a => !level1.includes(a));

    if (level1OpenKeys.length > 1) {
      level2OpenKeys = [];
    }
    setOpenKeys([level1OpenKeys.pop(), level2OpenKeys.pop()]);
  };

  const MenuTitle = ({ menu, hasIcon }) => (
    <span>
      {hasIcon && <IconFont style={{ fontSize: '20px' }} type={menu.icon} />}
      <span title={locale(menu.menuName)}>{locale(menu.menuName)}</span>
    </span>
  );

  const renderMenuItems = (items, level = 1) => (
    items.filter(hidePath).map((item) => {
      if (item?.children?.filter(hidePath).length > 0) {
        return (
          <Menu.SubMenu key={item.id} title={<MenuTitle menu={item} hasIcon={level === 1} />}>
            {renderMenuItems(item.children, level + 1)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={item.id}>
          <Link to={item.path} title={locale(item.menuName)}>
            <MenuTitle menu={item} hasIcon={level === 1} />
          </Link>
        </Menu.Item>
      );
    })
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineIndent={30}
      inlineCollapsed={false}
      style={{ width: 270 }}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      // 设置选中状态，使用打开的菜单即可覆盖
      selectedKeys={defaultOpenKeys}>
      {renderMenuItems(menus)}
    </Menu>
  );
};

const mapStateToProps = state => ({
  farm: state.farm,
  menus: state.menu.menus,
});

export default withRouter(
  connect(mapStateToProps)(LeftMenu)
);
