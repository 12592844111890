import './app.less';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LocaleProvider, notification } from 'antd';
// import zhCN from 'antd/lib/locale-provider/zh_CN';
import zhTW from 'antd/lib/locale-provider/zh_TW';
import BlankLayout from './layouts/BlankLayout';
import BaseLayout from './layouts/BaseLayout';
import MainLayout from './layouts/MainLayout';
import Download from './pages/Other/Download';

class App extends React.Component {
  constructor(props) {
    super(props);
    // 产线环境控制台不显示log信息
    const empty = () => {
    };
    if (process.env.NODE_ENV !== 'development') {
      console.log = empty;
      console.info = empty;
      console.table = empty;
    }

    // 提示信息通用设置
    notification.config({ duration: 2.5, });
    window.notification = notification;
  }

  getLocal = () => {
    switch (window.Bitmain.lng) {
      case 'zhtw':
        return zhTW;
      // case 'zh':
      //   return zhCN;
      default:
        return null;
    }
  };

  render = () => {
    const local = this.getLocal();
    return (
      <LocaleProvider locale={local}>
        <Switch>
          <Route path="/other/download" render={props => <Download location={props.location}/>} />
          <Route path="/home/*" render={props => <BaseLayout pathname={props.location.pathname} />} />
          <Route path="/other/*" render={props => <BlankLayout pathname={props.location.pathname} />} />
          <Route render={props => <MainLayout pathname={props.location.pathname} />} />
        </Switch>
      </LocaleProvider>
    );
  }
}

export default App;
