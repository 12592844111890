/* eslint-disable max-len */
import moment from 'moment/moment';
import timezone from 'moment-timezone';
import locale from '../i18n';
import store from '../store';

const util = {
  // 校验IP地址
  moment,
  timezone,
  isValidIP: (ip) => {
    const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    return reg.test(ip);
  },
  parsePeriod: (period, zone, format) => {
    // if (zone === true) {
    //   zone = util.getUserTimezone();
    // }
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    if (period && !Array.isArray(period)) {
      period = [period];
    }
    let startTime = '';
    if (period && period.length > 0) {
      startTime = period[0].format(format || 'YYYY-MM-DD 00:00:00');
      startTime = (zone || zone === 0) ? moment.utc(startTime, dateFormat) : moment(startTime, dateFormat);
      startTime = startTime.valueOf();
      if (zone || zone === 0) {
        startTime -= zone * 60 * 60 * 1000;
      }
    }
    let endTime = '';
    if (period && period.length > 1) {
      endTime = period[1].format(format || 'YYYY-MM-DD 23:59:59');
      endTime = (zone || zone === 0) ? moment.utc(endTime, dateFormat) : moment(endTime, dateFormat);
      endTime = endTime.valueOf();
      if (zone || zone === 0) {
        endTime -= zone * 60 * 60 * 1000;
      }
    }
    return [startTime, endTime];
  },
  // 查询条件中根据时区转换时间
  parsePeriodTimezone: (period, zone) => {
    if (!period || !Array.isArray(period)) { return []; }
    let startTime = '';
    if (period[0]) {
      const time = period[0].format('YYYY-MM-DD 00:00:00');
      startTime = util.getFarmZoneDate(time, zone).valueOf();
    }
    let endTime = '';
    if (period[1]) {
      const time = period[1].format('YYYY-MM-DD 23:59:59');
      endTime = util.getFarmZoneDate(time, zone).valueOf();
    }
    return [startTime, endTime];
  },
  /**
   * 时间戳格式化
   * @param {时间戳} time 时间戳
   * @param {string} dateFormat 格式
   * @param {string} zone 时区
   * @return {时间字符串}
   */
  formatFarmZoneDate: (time, dateFormat = 'YYYY-MM-DD HH:mm:ss', zone) => {
    if (!time) { return ''; }
    zone = zone || util.getFarmZone();
    return timezone.tz(time, zone).format(dateFormat);
  },
  /**
   * 根据时区格式化时间
   * @param {} time 时间对象或者字符串
   * @param {string} zone 时区字符串
   * @return {时间戳[]}
   */
  getFarmZoneDate: (time, zone) => {
    if (!time) { return null; }
    zone = zone || util.getFarmZone();
    return timezone.tz(time, zone);
  },
  // 获取当前矿场的时区
  getFarmZone: () => {
    const { farm } = store.getState();
    return farm?.timezone || timezone.tz.guess();
  },
  // 深拷贝
  deepClone: o => JSON.parse(JSON.stringify(o)),
  // 首字母大写
  capitalize: str => (str || '').replace(/^([a-z])/, w => w.toUpperCase()),
  // 首字母小写
  // uncapitalize: str => (str || '').replace(/^([A-Z])/, w => w.toLowerCase()),
  // escapeRegExpChar: str => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
  // 字符串操作
  map: (arr, callback) => {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
      result.push(callback(arr[i], i));
    }
    return result;
  },
  // 时间格式化 'YYYY-MM-DD HH:mm:ss'
  formatDate: (date, format = 'YYYY-MM-DD HH:mm', isUTC = false) => {
    if (!date) {
      return '';
    }
    if (!(date instanceof Date)) {
      date = isUTC ? moment.utc(date) : moment(date);
    }
    return date.format(format);
  },
  //秒转换成天时分秒
  formatDuring: (value) => {
    if (!value) {
      return 0 + locale('秒');
    }
    const days = parseInt(value / (60 * 60 * 24));
    const hours = parseInt((value % (60 * 60 * 24)) / (60 * 60));
    const minutes = parseInt((value % (60 * 60)) / (60));
    const seconds = value % (60);
    let result = '';
    if (days) {
      result += days + locale('天');
    }
    if (hours || result) {
      result += hours + locale('小时');
    }
    if (minutes || result) {
      result += minutes + locale('分');
    }
    if (seconds || result) {
      result += seconds + locale('秒');
    }

    return result;
  },
  // query 转为对象
  queryToJson: (query) => {
    const result = {};
    const pairs = query.replace(/^\?/, '').split('&');

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (pair) {
        const index = pair.indexOf('=');
        if (index > -1) {
          result[decodeURIComponent(pair.substring(0, index))] = decodeURIComponent(pair.substring(index + 1));
        } else {
          result[decodeURIComponent(pair)] = '';
        }
      }
    }

    return result;
  },
  queryParams: (data) => {
    const result = [];
    Object.keys(data).forEach((key) => {
      const value = data[key];
      // 去掉为空的参数
      if (![undefined, null, ''].includes(value)) {
        if (value.constructor === Array) {
          value.forEach((item) => {
            result.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
          });
        } else {
          result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
      }
    });
    return result.join('&');
  },
  getQueryString: (name, search) => {
    search = search || window.location.search.substr(1) || window.location.hash.split('?')[1];
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = search && search.match(reg);
    if (r != null) return unescape(r[2]); return null;
  },
  // 其他
  uuid: (id => () => ++id)(+new Date()),
  /**
   * 页面跳转
   * @param {string|object} path
   * @example 1.redirect('/list');
   * 2.redirect({
   *   pathname: '/detail',
   *   search: `?id=${row.locationId}`
   * });
   */
  // redirect: path => history.push(path),
  /** 单页面，重新加载content **/
  reload: () => {
    let search = location.hash.split('?');
    const path = search.length > 0 ? search[0] : '';
    search = search.length > 1 ? search[1] : '';
    let params = {};
    if (search) {
      params = util.queryToJson(search);
      params.random = Math.random();
    } else {
      params.random = Math.random();
    }
    let url = `${path}?`;
    Object.keys(params).forEach((key) => {
      url = `${url}${key}=${params[key]}&`;
    });
    location.href = url.substr(0, url.length - 1);
  },
  //验证转数字
  toNumber: (v) => {
    if (typeof v === 'number') {
      return v;
    }
    if (!v || !v.trim()) {
      return undefined;
    }
    const num = Number(v);
    return isNaN(num) ? v : num;
  },
  // cookie方法
  getCookie: (name) => {
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    const arr = document.cookie.match(reg);
    if (arr) {
      return unescape(arr[2]);
    }
    return null;
  },
  setCookie: (name, value, expires, path, domain, secure) => { //expires单位是毫秒
    const today = new Date();
    today.setTime(today.getTime());
    const expiresDate = new Date(today.getTime() + (expires));
    document.cookie = `${encodeURIComponent(name)}=${escape(value)}${(expires)
      ? `;expires=${expiresDate.toGMTString()}` : ''}${(path) ? `;path=${path}`
      : ''}${(domain) ? `;domain=${domain}` : ''}${(secure) ? ';secure' : ''}`;
  },
  trim: (str) => {
    if (typeof str === 'string') {
      return str.replace(/\s+/g, '');
    }
  },
  isNumber: value => !isNaN(parseFloat(value)) && isFinite(value),
  deepCopy: source => JSON.parse(JSON.stringify(source)),
  unitConversion: (number, source, target) => { //算力单位转换
    if (number == 0 || source === target) {
      return number;
    }
    // let decimal = 0;
    const fixedNum = 2;
    const unitArr = ['H/s', 'KH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s', 'EH/s'];

    if (!(number !== undefined && unitArr.indexOf(target) > -1 && unitArr.indexOf(source) > -1)) {
      return 0;
    }
    const diff = unitArr.indexOf(source) - unitArr.indexOf(target);
    return (number * (10 ** (diff * 3))).toFixed(fixedNum);
  },
  removeEmoji: content => content.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, ''),
  debounce(fn, ms) {
    let timeoutId;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, arguments);
      }, ms || 1000);
    };
  },
  timestampToTime: (timestamp) => {
    if (!timestamp) { return false; }
    const date = new Date(timestamp);
    const Y = `${date.getFullYear()}-`;
    const M = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-`;
    const D = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()} `;
    const h = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:`;
    const m = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return Y + M + D + h + m;
  },
  // 分类并计数
  groupCountBy: (rows, keys, fn) => rows.reduce((group, row) => {
    const groupId = keys.reduce((id, key) => { id += `${row[key]}`; return id; }, '');
    let item = group.find(a => a.groupId === groupId)??{ ...row, groupId, count: 0, };
    !item.count && group.push(item);
    item.count++;
    fn && (item = fn(item, row));
    return group;
  }, []),
  // 华氏度转摄氏度
  ftoc: (f) => {
    if (f === undefined || typeof f !== 'number') {
      return undefined;
    }
    const c = (f - 32) / 1.8;
    return c?.toFixed(1);
  }
};

export default util;
