import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.adapter = (options) => {
    const BASE_TIMEOUT = window.BASE_TIMEOUT || 1000;
    const response = {
      data: {},
      status: 200,
      statusText: 'OK',
      headers: {},
      options: {},
      request: {},
    };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const fulfill = (data) => {
          if (data) {
            resolve({
              ...response,
              data,
              options,
            });
          } else {
            reject({
              code: '200',
              message: 'success',
              data: {},
            });
          }
        };
        if (options.url.includes(window.apiServer)) {
          options.url = options.url.replace(window.apiServer, '');
        }
        import(`mock/${options.url.replace(/^\//, '')}`)
          .then((module) => {
            const handler = module[options.method];
            if (handler) {
              Promise.race([handler(options.params, options.data ? JSON.parse(options.data) : options.data, options)])
                .then((data) => {
                  fulfill(data);
                }, reject);
            } else {
              fulfill();
            }
          })
          .catch(() => {
            fulfill();
          });
      }, Math.floor(BASE_TIMEOUT * Math.random()));
    });
  };
}

export default axios;
