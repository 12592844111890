import en from './en.json';
import zhtw from './zhtw.json';
/**
 * 多语言
 * @param {object} defaultKey 文件中以中文作为key，对应多语言文件中的key
 * @param {object} replaceEnValue 英文的替换值，传入则不适用翻译文件中的值
 * @param {boolean} lng 需要转换的目标语言
 * @returns 转换后的值
 */
export default function locale(defaultKey, replaceEnValue, lng = window.Bitmain.lng) {
  if (process.env.NODE_ENV === 'development') {
    return defaultKey;
  }

  switch (lng) {
    case 'zhtw':
      return zhtw[defaultKey] || defaultKey;
    default://默认英文
      return replaceEnValue || en[defaultKey] || defaultKey;
  }
}
